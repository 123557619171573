import Image from "../../../Assets/manbox1.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection3 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col lg:flex-row items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-5"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] leading-tight text-[32px] font-semibold max-lg:text-center ">
              Versatile AI for Every Enterprise Need
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              boxMind's Multiple-Models AI System is the epitome of versatility
              in enterprise AI solutions. We understand that different tasks
              require different AI models, which is why our platform allows
              users to select the most suitable LLM for their specific needs,
              ensuring both efficiency and accuracy. Whether it's crafting
              compelling emails or performing complex data analyses, our system
              adapts to your unique requirements. This flexibility and
              customization make boxMind an indispensable tool in the modern
              enterprise's arsenal.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={Image}
              alt="pic"
              loading="lazy"
              className=" h-[389px] lg:mb-14 "
            />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection3;
