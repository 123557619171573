// import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Image1 from "../../Assets/boxMind_transparent.png";
// import Image2 from "../../Assets/boxMind_wide.png";
// import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import boxMind from "../../Assets/OkationLogo.png";

// const images = [Image1, Image2, boxMind];

const Mediakit = () => {
  //   const [selectedImage, setSelectedImage] = useState(null);

  //   const openImage = (index: any) => {
  //     setSelectedImage(index);
  //   };

  //   const closeImage = () => {
  //     setSelectedImage(null);
  //   };

  //   useEffect(() => {
  //     if (selectedImage !== null) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "visible";
  //     }

  //     return () => {
  //       document.body.style.overflow = "visible";
  //     };
  //   }, [selectedImage]);

  //   const prevImage = () => {
  //     setSelectedImage((prevIndex): any => {
  //       if (prevIndex === null) return null;
  //       return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
  //     });
  //   };

  //   const nextImage = () => {
  //     setSelectedImage((prevIndex): any => {
  //       if (prevIndex === null) return null;
  //       return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
  //     });
  //   };

  return (
    <section className=" bg-[#F8FBFD] flex flex-col justify-center gap-4 overflow-hidden lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind Mediakit</title>
        <meta
          name="description"
          content="Explore the boxMind.ai Media Kit to understand our brand identity. Learn about our logo's symbolism and how it represents our commitment to revolutionizing Gen AI."
        />
      </Helmet>
      <div className=" flex flex-col gap-8">
        <div className=" flex flex-col gap-2 text-justify lg:w-[650px] w-full mx-auto my-4 px-6 pb-6 bg-white shadow-lg rounded-lg">
          <div className=" flex justify-center items-center">
            <img
              src={Image1}
              alt="boxMind"
              className=" w-[200px]"
              loading="lazy"
            />
          </div>
          <p className="text-[#135890] lg:text-[25px] font-bold">
            boxMind.ai Logo
          </p>
          <div className="text-gray-700">
            Our new logo encapsulates the transformative and revolutionary
            essence of <span className=" font-bold">boxMind.ai</span>.
            Ingeniously designed using impossible shapes and illusionistic
            geometry, it symbolizes the fascinating reality of Gen AI – a field
            where the{" "}
            <span className=" font-bold">impossible becomes possible</span>. It
            is more than just a logo; it’s a visual narrative of the
            multi-disciplinary nature of Gen AI, encompassing Deep Learning,
            Large Language Models (LLMs), and other innovative AI realms.
          </div>
          <div className="text-gray-700">
            <div className=" flex items-center gap-2 py-4 ">
              <p className="text-[#135890] lg:text-[25px] font-bold">
                The hexagonal shapes
              </p>
              <img
                src={boxMind}
                alt="boxMind"
                loading="lazy"
                className=" w-[35px] h-[35px] object-cover"
              />
            </div>
            <p>
              a structure renowned for its efficiency and versatility in the
              natural world – mirrors our commitment to the multifaceted
              capabilities of Gen AI. Yet, our logo pushes beyond the expected:
              its optical illusion invites viewers to perceive it from various
              perspectives, be it a flat surface, an empty 3D cube, or a filled
              3D cube. This design choice reflects the transformative potential
              of Gen AI technology, capable of shifting our perspective and
              reality in unexpected ways.
            </p>
          </div>
          <p className="text-gray-700 my-2">
            Each hue in the multi-colored palette represents the different
            components that make AI a dynamic field – from Big Data and LLM
            Models to Machine Vision, each bringing a different color to the Gen
            AI spectrum. Finally, the name ‘boxMind.ai’ affirms our belief in
            the readiness and active role of Gen AI in shaping our world.
          </p>
          <p className="text-gray-700">
            Our logo stands not merely as our brand identifier, but a testament
            to boxMind.ai’s mission to revolutionize the landscape of Gen AI
            technology.
          </p>
        </div>
        {/* <div className=" flex justify-center">
          <div className=" lg:w-[750px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 items-center">
            {images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`img ${index + 1}`}
                className="cursor-pointer max-w-[200px] max-h-[200px] mx-auto"
                onClick={() => openImage(index)}
              />
            ))}
          </div>
          {selectedImage !== null && (
            <div className=" fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-30">
              <img
                src={images[selectedImage]}
                alt={`img ${selectedImage + 1}`}
                className=" max-w-[50%] max-h-[50%] lg:max-w-[80%] lg:max-h-[80%] mx-auto"
              />
              <button
                className=" absolute left-4 top-1/2 transform -translate-y-1/2"
                onClick={prevImage}
              >
                <FaAnglesLeft className=" text-white" size={25} />
              </button>
              <button
                className=" absolute right-4 top-1/2 transform -translate-y-1/2"
                onClick={nextImage}
              >
                <FaAnglesRight className=" text-white" size={25} />
              </button>
              <button
                className=" absolute top-[20%] right-4 z-30"
                onClick={closeImage}
              >
                Close
              </button>
            </div>
          )}
        </div> */}
      </div>
    </section>
  );
};

export default Mediakit;
