import { AnimatePresence, motion } from "framer-motion";
import Cloud from "../../../Assets/cloud.png";

const SubSection12 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row lg:gap-[140px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={Cloud}
              alt="Cloud"
              className=" lg:h-[389px] "
              loading="lazy"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] text-[32px] leading-tight font-semibold max-lg:text-center ">
              Flexible Deployment: On-Premises and Cloud Solutions
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              At boxMind, we understand that each enterprise has its unique
              operational landscape. That's why our state-of-the-art AI platform
              is designed for flexibility - offering seamless deployment both
              on-premises and on the cloud with providers like AWS, Azure,
              Google, and Huawei. This flexibility ensures that you can scale
              your AI operations efficiently, taking advantage of the cloud's
              vast resources and capabilities, while maintaining the high level
              of performance and security synonymous with the boxMind name.
              Harness the Power of boxMind - Anywhere, Anytime.
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection12;
