import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Image from "../../Assets/AIEthics.jpg";

type ButtonData = {
  id: number;
  title: string;
  text: string;
};

const buttonDataList: ButtonData[] = [
  {
    id: 1,
    title: "Transparency",
    text: "We believe in openness about our Gen AI technologies. We communicate clearly about what data we collect, how we use it, and how our Gen AI models work. We strive to make our Gen AI systems as understandable and explainable as possible, promoting trust with our users and partners.",
  },
  {
    id: 2,
    title: "Accountability",
    text: "We hold ourselves responsible for the impacts of our Gen AI technologies. boxMind.ai is dedicated to ongoing monitoring and evaluation of our Gen AI applications to identify and mitigate any potential negative outcomes. We also maintain a feedback mechanism for users and stakeholders to voice concerns or report issues.",
  },
  {
    id: 3,
    title: "Fairness",
    text: "Fair and unbiased Gen AI is a priority at boxMind.ai. We rigorously test our Gen AI models to avoid bias and ensure they treat all users fairly. We are committed to designing and implementing Gen AI systems that respect diversity and promote inclusion.",
  },
  {
    id: 4,
    title: "Privacy and Data Protection",
    text: "Respecting user privacy is at the heart of our Gen AI ethics. We follow best practices for data handling and protection, ensuring compliance with global data privacy regulations. We only use data for the purposes for which it was collected, and we employ robust security measures to protect it.",
  },
  {
    id: 5,
    title: "Beneficence",
    text: "We strive to ensure our Gen AI systems are designed and deployed for the benefit of all. We endeavor to minimize harm and maximize benefits, contributing to the betterment of society and individual lives.",
  },
  {
    id: 6,
    title: "Human-Centric Gen AI",
    text: "At boxMind.ai, we ensure our Gen AI technologies augment human capabilities without undermining human autonomy. Our Gen AI solutions are designed to empower users, not replace them.",
  },
  {
    id: 7,
    title: "Commitment to Continuous Learning",
    text: "As Gen AI technology continues to evolve, so too will our approach to Gen AI ethics. We are committed to staying informed about emerging ethical challenges and adjusting our practices as needed. We are also open to dialogue with our users, partners, and wider society to learn from diverse perspectives and experiences.",
  },
];

const AIEthics = () => {
  const [selectedButtonId, setSelectedButtonId] = useState<number | null>(null);
  const [displayText, setDisplayText] = useState<string | null>(null);

  useEffect(() => {
    if (buttonDataList.length > 0) {
      setSelectedButtonId(buttonDataList[0].id);
      setDisplayText(buttonDataList[0].text);
    }
  }, []);

  const handleButtonClick = (id: number) => {
    setSelectedButtonId(id);
    const selectedButton = buttonDataList.find((button) => button.id === id);
    if (selectedButton) {
      setDisplayText(selectedButton.text);
    }
  };

  return (
    <section className=" bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind AI-Ethics</title>
        <meta
          name="description"
          content="Explore boxMind's commitment to ethical AI. Learn about our dedication to transparency, accountability, fairness, privacy, and human-centric Gen AI technologies."
        />
      </Helmet>
      <div className="flex justify-center text-center">
        <img
          src={Image}
          loading="lazy"
          alt="pic"
          className=" lg:w-[850px] w-full h-full object-cover"
        />
      </div>
      <div className="text-justify lg:w-[850px] mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
        <p className="text-gray-700">
          At boxMind.ai, we are committed to ensuring our artificial
          intelligence (AI) applications and services adhere to the highest
          ethical standards. Our philosophy prioritizes transparency,
          accountability, fairness, and respect for user privacy.
        </p>
        <div className=" flex flex-col lg:flex-row lg:justify-between gap-4 my-4 lg:gap-8 lg:my-8">
          <div className=" flex flex-col max-lg:justify-center max-lg:items-center gap-3">
            {buttonDataList?.map((button) => (
              <div key={button.id}>
                <button
                  onClick={() => handleButtonClick(button.id)}
                  className={`${
                    selectedButtonId === button.id
                      ? "bg-[#1B75BB] text-white"
                      : "bg-[#F1F2F3] hover:bg-[#1B75BB] hover:text-white text-gray-700"
                  } duration-300 w-[250px] font-bold py-2 px-4 rounded`}
                >
                  {button.title}
                </button>
              </div>
            ))}
          </div>
          <div>
            {displayText && (
              <div>
                <p className="font-semibold text-gray-700">{displayText}</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-gray-700">
            At boxMind.ai, we are not just developing AI technologies; we are
            shaping the future of AI, a future that is ethical, responsible, and
            beneficial for all.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AIEthics;
