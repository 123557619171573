import { useState } from "react";
import Image from "../../../Assets/GPU_server.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection11 = () => {
  const [showFullText, setShowFullText] = useState(false);

  const text =
    "At boxMind, we go beyond just software; we offer a complete closed-loop AI solution that includes state-of-the-art Nvidia GPU servers and workstations, integral for powering your private AI. Understanding that each enterprise has unique requirements, we take a consultative approach to select the best GPU server for your specific needs. Factors such as the number of users, data size, and specific processing demands are meticulously considered. Our team has conducted intensive testing and research, focusing on key performance metrics like tokens per second, VRAM capacity, and user concurrency, ensuring that your AI system operates at peak efficiency regardless of load. This approach results in significant cost savings for your business. You're not just investing in an AI solution; you're acquiring a complete, private AI ecosystem - from data models and LLMs to GPU servers. With boxMind, you own a self-contained, high-performance AI platform, tailored to your enterprise's needs, ensuring a powerful, efficient, and secure AI experience. This commitment to a comprehensive, customizable AI infrastructure positions boxMind as a pioneer in delivering bespoke, end-to-end AI solutions for the enterprise.";

  const truncatedText = showFullText ? text : `${text.slice(0, 338)}...`;

  return (
    <section className="flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className="flex flex-col lg:flex-row lg:gap-[140px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] text-[32px] leading-tight font-semibold max-lg:text-center">
              Own your private AI GPU server
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal leading-[28px]">
              {truncatedText}
              {text.length > 300 && (
                <button
                  className="text-blue-500 underline cursor-pointer"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Read Less" : "Read More"}
                </button>
              )}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img src={Image} alt="pic" className="h-[389px]" loading="lazy" />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection11;
