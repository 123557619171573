import { Helmet } from "react-helmet";
import Cards from "./Cards/Cards";

const CoFounders = () => {
  return (
    <section className=" bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind Co-Founders</title>
        <meta
          name="description"
          content="Discover the inspiring journeys of Dr. Eng. Mohamad Malli and Mr. Mazen Lahham, the co-founders of boxMind. Learn about their extensive expertise and contributions to the AI industry."
        />
      </Helmet>
      <Cards />
    </section>
  );
};

export default CoFounders;
