import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage/HomePage";
import NavBar from "./components/NavBar/NavBar";
import CoFounders from "./components/CoFounders";
import Philosophy from "./components/Philosophy";
import AIHeroes from "./components/AIHeroes";
import FAQ from "./components/FAQ";
import { useEffect, useState } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import Events from "./components/Events";
import PrivacyPolicy from "./components/Privacy Policy";
import AIEthics from "./components/AI Ethic";
import Mediakit from "./components/Mediakit";
import Articles from "./components/Articles";
import NotFoundPage from "./components/404";
import UseCases from "./components/Use-Cases";

function App() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section
      className=" bg-[#F8FBFD] w-full h-screen"
      style={{ fontFamily: "Roboto, sans-serif" }}
    >
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/ai-heroes" element={<AIHeroes />} /> */}
          <Route path="/co-founders" element={<CoFounders />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/events" element={<Events />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/ai-ethics" element={<AIEthics />} />
          <Route path="/mediakit" element={<Mediakit />} />
          {/* <Route path="/use-cases" element={<UseCases />} /> */}
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      {showButton && (
        <button
          onClick={goUp}
          className="p-3 rounded bg-[#135991] m-3 sm:m-10  right-0 bottom-0 cursor-pointer fixed hover:bg-[#244865] duration-300 outline-none"
        >
          <AiOutlineArrowUp color="white" />
        </button>
      )}
    </section>
  );
}

export default App;
