import Image from "../../../Assets/Man_Shouting_in a_box_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection1 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col lg:flex-row lg:gap-[100px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-5"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] leading-tight text-[32px] font-semibold  max-lg:text-center">
              Welcome to the Future of Enterprise AI: boxMind Enterprise
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal leading-[28px]">
              Discover the next frontier in enterprise-level data analysis with
              boxMind Enterprise, a groundbreaking AI solution that transcends
              traditional data processing limitations. Powered by advanced Large
              Language Models (LLMs), our platform offers an unprecedented
              ability to glean insights from both structured and unstructured
              data. This is more than just a tool; it's a visionary approach to
              data analytics, designed to equip businesses with the intelligence
              and agility required to thrive in today's data-driven world. Join
              us in exploring the vast potential of AI-powered data analysis and
              redefine what's possible in your enterprise.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={Image}
              alt="pic"
              loading="lazy"
              className=" w-full h-[489px]"
            />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection1;
