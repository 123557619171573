import ReactPlayer from "react-player/youtube";

const VideoSection = () => {
  const videoUrl =
    "https://www.youtube.com/embed/Zih11gZN388?si=-9sK5knMwCwjYG-z";
  return (
    <section className=" lg:pt-[5%] pt-[25%] banner lg:h-[70vh] bg-no-repeat overflow-hidden bg-cover w-full flex justify-center ">
      <div className="px-4 py-4 lg:pt-10 max-sm:flex max-sm:justify-center max-sm:items-center max-sm:w-full">
        <ReactPlayer
          url={videoUrl}
          config={
            {
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  showinfo: 0,
                },
              },
            } as any
          }
        />
      </div>
    </section>
  );
};

export default VideoSection;
