import Image from "../../../Assets/Mainholdabox_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection7 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row lg:gap-[140px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img src={Image} alt="pic" className=" h-[389px] " loading="lazy" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] text-[32px] leading-tight font-semibold max-lg:text-center ">
              Handling Big Data with Unparalleled Efficiency
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              In an era where data is growing exponentially, boxMind's Big Data
              Handling capabilities stand out. Our platform is designed with
              hyperscale capability, allowing it to manage and process vast
              volumes of data efficiently and effectively. This was exemplified
              in a notable test where boxMind processed the entire content of
              Wikipedia, including over 18 million sub-articles, in a mere 20
              milliseconds. This level of efficiency demonstrates our platform's
              capability to handle large-scale data sets, making it an ideal
              solution for enterprises dealing with substantial amounts of
              information.
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection7;
