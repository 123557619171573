// import BoxMind from "../../../Assets/boxMind_transparent.png";
import Group1 from "../../../Assets/Group.svg";
import Frame from "../../../Assets/Frame.svg";
import { Link as ScrollLink } from "react-scroll";

const Hero = () => {
  return (
    <section className=" pb-10 w-full ">
      <div className="flex flex-col justify-center items-center gap-2">
        {/* <img src={BoxMind} alt="boxMind" loading="lazy" width={166} height={130} /> */}
        <div className=" relative text-center items-center px-4 lg:w-[874px] gradient-text">
          <p className="pt-6 lg:text-[40px] text-[27px] font-semibold ">
            Own Your ChatGPT Infrastructure:
          </p>
          <p className="lg:text-[40px] text-[27px] font-semibold">
            Take Control of Gen AI
          </p>
          <div className=" max-lg:hidden lg:absolute lg:top-0 lg:left-[70px]">
            <img src={Group1} loading="lazy" alt="group" />
          </div>
        </div>
        <p className=" lg:w-[789px] px-4 text-center text-neutral-400 lg:text-xl text-[20px] font-normal lg:leading-[33px] tracking-tight">
          Privacy-Focused and Cost-Effective: A Gen AI Platform Revealing New
          Data Insights and Pioneering a New Era of Enterprise Knowledge
        </p>
        <div className=" relative w-full">
          <div className=" flex justify-center">
            <div className=" mt-4 bg-[#135991] hover:bg-[#23435e] duration-300 cursor-pointer text-white rounded-md px-4 py-2">
              <ScrollLink to="contact-us" smooth={true} duration={700}>
                Contact Sales
              </ScrollLink>
            </div>
          </div>
          <div className="lg:absolute lg:right-[35%] lg:-top-[10px] max-lg:hidden ">
            <img src={Frame} loading="lazy" alt="Frame" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
