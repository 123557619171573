import { Helmet } from "react-helmet";
import Post1 from "../../Assets/post1.jpg";
import Post2 from "../../Assets/post2.jpg";
import Post3 from "../../Assets/post3.jpg";
import Post4 from "../../Assets/post4.jpg";
import Post5 from "../../Assets/post5.jpg";
import Post6 from "../../Assets/post6.jpg";
import Post7 from "../../Assets/post7.jpg";
import Post8 from "../../Assets/post8.jpg";
import Post9 from "../../Assets/post9.jpg";
import Post10 from "../../Assets/post10.jpg";
import Post11 from "../../Assets/post11.jpg";
import Post12 from "../../Assets/post12.jpg";
import Post13 from "../../Assets/post13.jpg";
import Post14 from "../../Assets/post14.jpg";
import Post15 from "../../Assets/post15.jpg";
import Post16 from "../../Assets/post16.jpg";
import Post17 from "../../Assets/post17.jpg";
import Post18 from "../../Assets/post18.jpg";
import Post19 from "../../Assets/post19.jpg";
import Post20 from "../../Assets/post20.jpg";
import Post21 from "../../Assets/post21.jpg";
import Post22 from "../../Assets/post22.jpg";
import Post23 from "../../Assets/post23.jpg";
import Post24 from "../../Assets/Post24.jpg";
import Post25 from "../../Assets/post25.jpg";
import Post26 from "../../Assets/post26.jpg";
import Post27 from "../../Assets/Post27.jpg";
import Post28 from "../../Assets/post28.jpg";
import Post29 from "../../Assets/post29.jpg";
import Post30 from "../../Assets/post30.jpg";
import Post31 from "../../Assets/post31.jpg";

interface Article {
  photos: string;
  title: string;
  description: string;
  link: string;
  Date: string;
}

const articleData: Article[] = [
  {
    title: `Bridging the Gap: Human-AI Trust Explored in Netflix's Atlas Movie`,
    description: `
Movies have always played a significant role in shaping our perceptions of technology, particularly in AI and robotics. Science fiction films like "I, Robot" and "her" push the boundaries of our imagination, often adding fantastical elements. However, with the advent of technologies like Generative AI, these cinematic visions are starting to feel more plausible. The latest movie, "Atlas," on Netflix, delves into the complex relationship between humans and AI, exploring themes of trust, cooperation, and the potential dangers of advanced AI.`,
    photos: Post31,
    Date: "May 27, 2024",
    link: "https://www.linkedin.com/pulse/bridging-gap-human-ai-trust-explored-netflixs-atlas-movie-lahham-w59tf/",
  },
  {
    title: `Reading Between the Lines: The New ChatGPT 4o`,
    description: `
Our generation is so fortunate to witness a pivotal moment in human history where AI progress is measured by days instead of decades, as in the recent past. This rapid advancement is mind-blowing, and personally, I can't stop thinking about the enormous impact of new innovations emerging daily. With the release of the new ChatGPT 4o, it is hard to predict the exact trajectory of AI, but one thing is for sure: this development is exciting and transformative. In this article, I am thrilled to share some interesting points, features, and also questions and concerns about ChatGPT 4o. By reading between the lines and asking some questions, we can delve deeper into what this new version means for the future of AI.`,
    photos: Post30,
    Date: "May 14, 2024",
    link: "https://www.linkedin.com/pulse/reading-between-lines-new-chatgpt-4o-mazen-lahham-o7a5c/",
  },
  {
    title: `Understanding Large Action Models (LAM) vs (LLM)!`,
    description: `
As an AI researcher and a professional deeply entrenched in the world of AI, I’ve had the opportunity to witness firsthand the rapid evolution and expansion of artificial intelligence. We are truly in a 'spring era' of AI, a period marked by an explosive growth and diversification of AI technologies, reminiscent of a renaissance more than a mere trend. Among the most intriguing developments in this flourishing period is the emergence of Large Action Models (LAMs).`,
    photos: Post29,
    Date: "May 6, 2024",
    link: "https://www.linkedin.com/pulse/understanding-large-action-models-lam-vs-llm-mazen-lahham-hw0cf/",
  },
  {
    title: `With GenAI, What Happens When We Reach a Yottabyte 1,000,000,000,000,000,000,000,000 bytes?`,
    description: `
In an age where data is the new oil, we're hurtling towards a future defined not just by zettabytes but yottabytes. Current estimates, propelled by the burgeoning IoT sector, project a staggering leap to 175 zettabytes by 2025. Yet, these forecasts may not fully encapsulate the seismic shift brought by generative AI. As this technology redefines content creation, it's poised to exponentially amplify data production, far beyond the realms of current IoT projections.`,
    photos: Post28,
    Date: "March 29, 2024",
    link: "https://www.linkedin.com/pulse/genai-what-happens-when-we-reach-yottabyte-bytes-mazen-lahham-fjaef/",
  },
  {
    title: `Meet Devin: The Superpowered Programmer – Why We Still Desperately Need Human Coders`,
    description: `
In the beginning of the year, amidst a heated debate about AI's impact on jobs, I attended the first Databricks Summit in California where a bold claim was made: "English is the only programming language." This notion struck me as both promising and indicative of the seismic shifts awaiting us in the tech landscape. As I hypothesized, programmers were on the cusp of being the first majorly impacted by the Gen AI revolution.`,
    photos: Post27,
    Date: "March 14, 2024",
    link: "https://www.linkedin.com/pulse/meet-devin-superpowered-programmer-why-we-still-need-human-lahham-bzjdf/",
  },
  {
    title: `Blurring Realities: How Sora's AI Unveils a New Dimension of Virtual Existence`,
    description: `
In the ever-evolving landscape of technology, artificial intelligence (AI) has emerged not just as a field of study, but as a revolutionary force reshaping our world. The journey of AI, tracing back to its nascent days in the mid-20th century, reflects a saga of human ingenuity and relentless pursuit of technological advancement. `,
    photos: Post26,
    Date: "February 20, 2024",
    link: "https://www.linkedin.com/pulse/blurring-realities-how-soras-ai-unveils-new-dimension-mazen-lahham-suvsf/",
  },
  {
    title: `Apple Vision Pro and the Evolution of Human-AI Interaction!`,
    description: `
As we stand at the threshold of a new era in technology, the recent unveiling of Apple's Vision Pro Mixed Reality VR/AR headset is not just another advancement; it's a paradigm shift in how we interact with the digital world. This shift is reminiscent of the revolutionary transformation from command-line interfaces to graphical user interfaces (GUIs) in personal computing, signaling a new chapter in human-computer interaction and now in human-AI interaction.`,
    photos: Post25,
    Date: "February 7, 2024",
    link: "https://www.linkedin.com/pulse/apple-vision-pro-evolution-human-ai-interaction-mazen-lahham-4bfbf/",
  },
  {
    title: `When Grandpa Embraced the Smartphone: Navigating the Transformative Tides of Generative AI`,
    description: `
The story of technological transformation in our society is a tale of gradual acceptance and sudden breakthroughs. The emergence of the internet in the late 20th century marked the beginning of a new era in human communication and information exchange. It was a time of exploration and excitement, but also of uncertainty, as the world grappled with the vast potential of being interconnected.`,
    photos: Post24,
    Date: "February 1, 2024",
    link: "https://www.linkedin.com/pulse/when-grandpa-embraced-smartphone-navigating-tides-ai-mazen-lahham-zyeef/",
  },
  {
    title: `Bridging the Worlds: The Synergy and Disparity Between Traditional and Generative AI`,
    description: `
The landscape of Artificial Intelligence (AI) is a complex tapestry, woven with diverse technologies and methodologies. Amidst this intricate weave, a common source of confusion arises: distinguishing between Traditional AI and Generative AI. This article delves into their intricacies, starting from the roots of Traditional AI, traversing through its challenges, and arriving at the dawn of Generative AI.`,
    photos: Post23,
    Date: "January 18, 2024",
    link: "https://www.linkedin.com/pulse/bridging-worlds-synergy-disparity-between-traditional-mazen-lahham-gubyf/",
  },
  {
    title: `Mixtral 8x7B: Legend of the Eight Samurai`,
    description: `
With the advent of the Mixtral 8x7B model, which harnesses the collective might of eight distinct 7-billion parameter models, my mind casts back to the classic movie the "Legend of the Eight Samurai."`,
    photos: Post1,
    Date: "December 20, 2023",
    link: "https://www.linkedin.com/pulse/mixtral-8x7b-legend-eight-samurai-mazen-lahham-36mwf/?trackingId=6uTdDSpuROaOVxvbkspWyg%3D%3D",
  },
  {
    title: `This is the end - Hold your breath and count to ten! Gemini: Google's Leap into Human-like AI.`,
    description: `
For my regular readers who have followed my discussions on the evolution of generative AI, you might recall my earlier skepticism in "Did Google Miss the Mark on Generative AI?" I critiqued Google's apparent lag in the race of Large Language Models (LLMs).`,
    photos: Post2,
    Date: "December 9, 2023",
    link: "https://www.linkedin.com/pulse/end-hold-your-breath-count-ten-gemini-googles-leap-ai-mazen-lahham-3rtsf/?trackingId=bIvVEk1yTRaf4k0dIBMQow%3D%3D",
  },
  {
    title: `The Father of Robotics: Al-Jazari and the New Dawn with Elon Musk's Optimus`,
    description: `
The journey into the world of robotics often conjures images of cutting-edge technology and futuristic innovations. However, this journey, in reality, begins in the medieval era, nearly a millennium ago, with a visionary known as Ismail Al-Jazari.`,
    photos: Post3,
    Date: "November 29, 2023",
    link: "https://www.linkedin.com/pulse/father-robotics-al-jazari-new-dawn-elon-musks-optimus-mazen-lahham-5py5f/?trackingId=MntcsvnOSDazYsu25Ss3Bw%3D%3D",
  },
  {
    title: `Beyond the Hype of GPTs, A Reality Check in AI Development. The boxMind.ai Edge Over GPTs`,
    description: `
In the evolution of software development, we've witnessed the rise of automation tools that promised to simplify and streamline the coding process. These tools, often referred to as No-code or Low-Code platforms, have been the focus of significant investment by several companies.`,
    photos: Post4,
    Date: "November 14, 2023",
    link: "https://www.linkedin.com/pulse/beyond-hype-gpts-reality-check-ai-development-boxmindai-mazen-lahham-1665f/?trackingId=%2Be3la2zJTwCzCyCzKzPDyg%3D%3D",
  },
  {
    title: `ChatGPT-4 Turbo: The New AI Boss on the Block and Leaving Competitors in the Dust`,
    description: `
In a bold move set to redefine the AI landscape, ChatGPT launches its latest iteration, GPT-4 Turbo, which promises to revolutionize the way developers and companies interact with AI technologies. `,
    photos: Post5,
    Date: "November 7, 2023",
    link: "https://www.linkedin.com/pulse/chatgpt-4-turbo-new-ai-boss-block-leaving-competitors-mazen-lahham-97t1e/?trackingId=tgmj1QgbTA%2BnvX7ciDMDKQ%3D%3D",
  },
  {
    title: `Regulating AI: A Path to Safety or a Roadblock to Innovation?`,
    description: `
In an age where artificial intelligence (AI) seamlessly integrates into the fabric of our daily lives, the dichotomy of its potential and peril has prompted a call for oversight. The recent executive order by U.S. President Joe Biden signifies a pivotal move towards this direction. `,
    photos: Post6,
    Date: "November 3, 2023",
    link: "https://www.linkedin.com/pulse/regulating-ai-path-safety-roadblock-innovation-mazen-lahham-ksclf/?trackingId=P7vbAy%2BeToOFoL5Zg0juBg%3D%3D",
  },
  {
    title: `Is AI's Father Geoffrey Hinton Following the Path of Atomic Bomb's Robert Oppenheimer?`,
    description: `
Geoffrey Hinton is often dubbed the "father of AI" for foundational contributions to machine learning and deep learning. His development of algorithms for training neural networks revolutionized the field and accelerated progress in a way that is comparable to the introduction of calculus in physics.`,
    photos: Post7,
    Date: "October 23, 2023",
    link: "https://www.linkedin.com/pulse/ais-father-geoffrey-hinton-following-path-atomic-bombs-mazen-lahham/?trackingId=B7vlBLWXSbSfoSVpdXnpEA%3D%3D",
  },
  {
    title: `Why Medium is Banning ChatGPT from Scanning Their Web Site: A Deep Dive into Fairness, Copyright, and the Future of Publishing`,
    description: `
Artificial Intelligence, particularly machine learning and natural language processing models, have profoundly impacted various industries, including publishing.`,
    photos: Post8,
    Date: "October 20, 2023",
    link: "https://www.linkedin.com/pulse/why-medium-banning-chatgpt-from-scanning-sites-deep-dive-mazen-lahham/?trackingId=mHSJVEsfRZGkv3op89%2BdmQ%3D%3D",
  },
  {
    title: `The Forgotten Genius: Why Dennis Ritchie Matters as Much as Steve Jobs?`,
    description: `
Steve Jobs was a titan of industry whose death dominated headlines. Yet, just over a week later, the death of another giant, Dennis Ritchie, went almost unnoticed.`,
    photos: Post9,
    Date: "October 12, 2023",
    link: "https://www.linkedin.com/pulse/forgotten-genius-why-dennis-ritchie-matters-much-steve-mazen-lahham/?trackingId=cQqOqhwYRiSbLO5SnlFtdg%3D%3D",
  },
  {
    title: `Is Hugging Face the New Linux? The Wrestling Match Between Open Source and Corporate AI`,
    description: `
In the past, there was a big fight between huge companies like Microsoft and supporters of open-source software like Linux. Linus Torvalds, who created Linux, once said, "If Microsoft ever makes software for Linux, it means I've won."`,
    photos: Post10,
    Date: "October 8, 2023",
    link: "https://www.linkedin.com/pulse/hugging-face-new-linux-wrestling-match-between-open-source-lahham/?trackingId=Y5iyKmGjTIiYD0doSNySdA%3D%3D",
  },
  {
    title: `Did Google Miss the Mark on Generative AI and Smart Glasses? The Tale of Two Futures`,
    description: `
Twenty-five years ago, Google emerged as a small search engine with a big ambition: to organize the world's information and make it universally accessible and useful. Fast forward to today, it's safe to say that Google has not only achieved that goal but also transformed how we interact with the digital realm.`,
    photos: Post11,
    Date: "September 30, 2023",
    link: "https://www.linkedin.com/pulse/why-did-google-miss-mark-generative-ai-smart-glasses-tale-lahham/?trackingId=A1mmUcz7QAq61SBLJ4%2FkOg%3D%3D",
  },
  {
    title: `When Everyone Becomes Einstein: The Revolution and Risks of LLM in AI`,
    description: `
The rapid advancements in AI, particularly in Lifelong Learning Models (LLM), have ushered in a new era of intelligence that is beyond our wildest imagination. Imagine a world where AI systems could make us all as brilliant as Einstein. While this may sound like a utopia, it's crucial to delve into the implications, risks, and benefits this leap in technology could bring.`,
    photos: Post12,
    Date: "September 22, 2023",
    link: "https://www.linkedin.com/pulse/when-everyone-becomes-einstein-revolution-risks-llm-ai-mazen-lahham/?trackingId=PH%2B5Q4tMRX2bsJNLlQ3b%2Fw%3D%3D",
  },
  {
    title: `Phi 1.5 Why 'Textbooks Are All You Need' for Smaller, Smarter LLM Models`,
    description: `
As many of you already know, I have long been an advocate for refining large language models (LLMs) through the use of quality data. The prevailing trend of simply increasing the number of parameters is an approach that I believe will eventually reach a dead-end. `,
    photos: Post13,
    Date: "September 16, 2023",
    link: "https://www.linkedin.com/pulse/phi-15-why-textbooks-all-you-need-smaller-smarter-llm-mazen-lahham/?trackingId=aPEis7iRT9iQP%2F4RpjrS%2Fg%3D%3D",
  },
  {
    title: `The Feynman Technique: The Bridge Between Complexity and Understanding in AI`,
    description: `
Richard Feynman was no ordinary physicist. He was instrumental in the Manhattan Project, unmasked the cause of the Challenger Space Shuttle disaster, and even bagged a Nobel Prize in Physics.`,
    photos: Post14,
    Date: "September 15, 2023",
    link: "https://www.linkedin.com/pulse/feynman-technique-bridge-between-complexity-ai-mazen-lahham/?trackingId=bIuOnFaxTxukpES5z22ksA%3D%3D",
  },
  {
    title: `Decoding the "Pinocchio Syndrome" in Language Models: Challenges and Solutions in a Technical Light`,
    description: `
In the fast-paced world of Artificial Intelligence, Large Language Models (LLMs) like GPT-4 have become pivotal. They are fascinating, yet, like the classic character Pinocchio, they sometimes "tell tales" or produce false information.`,
    photos: Post15,
    Date: "September 14, 2023",
    link: "https://www.linkedin.com/pulse/decoding-pinocchio-syndrome-language-models-solutions-mazen-lahham/?trackingId=F4y%2FiNOATVGFjKkdIaFwkQ%3D%3D",
  },
  {
    title: `Why I Think the New Falcon 180B Will Not Benefit the Open Source AI Community`,
    description: `
The open-source AI community has been buzzing with the release of Falcon's newest model, boasting a jaw-dropping 180 billion parameters. While the technical achievement is undoubtedly commendable, one has to ask: is this the direction that benefits the broader AI community? I argue that it's not, and here's why.`,
    photos: Post16,
    Date: "September 7, 2023",
    link: "https://www.linkedin.com/pulse/why-i-think-new-falcon-180b-benefit-open-source-ai-community-lahham/?trackingId=uFktZzx%2FSg2FDypr7zY5IA%3D%3D",
  },
  {
    title: `Utilizing ChatGPT for Ethical Hacking: Creating a VPN IP Rotation Script`,
    description: `
In today's fast-paced digital age, the role of ethical hacking in ensuring cybersecurity cannot be overstated. Ethical hackers (and non-Ethical) often employ a variety of tools and scripts to test systems for vulnerabilities. `,
    photos: Post17,
    Date: "September 6, 2023",
    link: "https://www.linkedin.com/pulse/utilizing-chatgpt-ethical-hacking-creating-vpn-ip-rotation-lahham/?trackingId=%2Fc4pUhyzR1qutKTGogocaA%3D%3D",
  },
  {
    title: `Back to the Future: How Generative AI Revives GOFAI Paradigms with Recent Research`,
    description: `
In the current age of AI innovation, the spotlight is often fixed on groundbreaking advancements. Yet, it is crucial to remember that today's 'novelties' are deeply rooted in foundational work from decades past. `,
    photos: Post18,
    Date: "September 3, 2023",
    link: "https://www.linkedin.com/pulse/back-future-how-generative-ai-revives-gofai-paradigms-mazen-lahham/?trackingId=KCclPw1BSpuJSiqOjbkUtA%3D%3D",
  },
  {
    title: `A Quick Test of Jais-13B vs. ChatGPT-4 on NVIDIA A100 80GB`,
    description: `
On August 30, 2023, Inception, a G42 company pioneering the advancement of AI, made an exciting announcement: the open-source release of Jais, the world's most advanced Arabic Large Language Model.`,
    photos: Post19,
    Date: "August 31, 2023",
    link: "https://www.linkedin.com/pulse/quick-test-jais-13b-vs-chatgpt-4-nvidia-a100-80gb-mazen-lahham/?trackingId=oDgsAF%2BmSd%2BjqE5GLu4%2FCg%3D%3D",
  },
  {
    title: `Could AI Be Your Next Healthcare Advisor? The Dog Life Saved by ChatGPT!`,
    description: `
Cooper, an everyday dog owner, never expected to become a Twitter sensation when he turned to ChatGPT for help with his sick dog, Sassy. Diagnosed with a tick-borne disease by the vet, Sassy initially responded well to treatment. `,
    photos: Post20,
    Date: "August 27, 2023",
    link: "https://www.linkedin.com/pulse/could-ai-your-next-healthcare-advisor-dog-life-saved-chatgpt-lahham/?trackingId=5dE7h%2B5ZQM2LNIb0aKbYYg%3D%3D",
  },
  {
    title: `A Conscious Machine: The Concept of Time and Space in Generative AI`,
    description: `
Yesterday, I found myself reflecting on a strange idea: what if a Generative AI, like the incoming ChatGPT-5, was allowed to run around the clock without human supervision? This machine would not only process data but also acknowledge its current space and time, adapting and evolving with each passing moment.  `,
    photos: Post21,
    Date: "August 21, 2023",
    link: "https://www.linkedin.com/pulse/conscious-machine-concept-time-space-generative-ai-mazen-lahham/?trackingId=K7xGPvBNQQub%2FsrYruvLkQ%3D%3D",
  },
  {
    title: `Embracing Research & Quality in AI: A Comprehensive Approach at boxMind.ai`,
    description: `
In the rapidly evolving landscape of Artificial Intelligence (AI), where cutting-edge models like ChatGPT have created a pathway for genuine human-machine communication, there is a simultaneous rush among companies to deliver quick solutions.`,
    photos: Post22,
    Date: "August 17, 2023",
    link: "https://www.linkedin.com/pulse/embracing-research-quality-ai-comprehensive-approach-okationai-mazen/?trackingId=40U9H4KaQySRCOJZJhC4Fw%3D%3D",
  },
];

const Articles = () => {
  return (
    <section className="bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%] px-4">
      <Helmet>
        <title>boxMind Articles</title>
        <meta
          name="description"
          content="Dive into boxMind's rich collection of articles exploring the latest trends, innovations, and discussions in the world of AI. From historical insights to future forecasts, join us in exploring AI's transformative journey."
        />
      </Helmet>
      <div className=" flex justify-center items-center">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-x-8">
          {articleData.map((article, index) => (
            <div
              key={index}
              className=" text-justify lg:w-[500px] mx-auto my-8 p-6 bg-white hover:bg-gray-300 duration-300 shadow-lg rounded-lg"
            >
              <a href={article.link} target="blank">
                <h2 className=" text-[20px] lg:text-[25px] text-center font-bold pb-2">
                  {article.title.length > 60
                    ? article.title.substring(0, 60) + "..."
                    : article.title}
                </h2>
                <div className=" flex flex-col items-center justify-center py-2">
                  <img
                    src={article.photos}
                    alt="img"
                    loading="lazy"
                    className=" w-full lg:w-[500px] lg:h-[250px] object-cover rounded-md"
                  />
                  <p className="text-gray-600 max-w-[1200px] lg:h-[150px] text-justify mb-4 whitespace-break-spaces">
                    {article.description.length > 300
                      ? article.description.substring(0, 300) + "..."
                      : article.description}
                  </p>
                </div>
                <div className="flex justify-end">
                  <p className=" text-gray-500">{article.Date}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Articles;
