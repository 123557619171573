import { useState } from "react";
import { PiChatsDuotone } from "react-icons/pi";
import Chat from "../../../Assets/Chat_Interface.jpeg";
import Search_Engine from "../../../Assets/Search_Engine.jpeg";
import Agents from "../../../Assets/agents.png";
import Models from "../../../Assets/LLmModels.jpeg";
import { CiSearch } from "react-icons/ci";
import { MdEngineering, MdSupportAgent } from "react-icons/md";
import { GoDependabot } from "react-icons/go";
import Cloud from "../../../Assets/cloud_storage.png";
import { TbPlugConnected } from "react-icons/tb";
import Pipeline from "../../../Assets/AI_Pipeline.jpg";
import { VscDatabase } from "react-icons/vsc";
import { AnimatePresence, motion } from "framer-motion";
import AIForm from "../../../Assets/AIForms.jpg";

const DashboardSection = () => {
  const [selectedSection, setSelectedSection] = useState<number | null>(1);

  const handleSectionClick = (sectionIndex: number) => {
    setSelectedSection(sectionIndex);
  };

  const getBackgroundColor = (sectionIndex: number) => {
    return selectedSection === sectionIndex ? "#135991" : "#f2f2fa";
  };

  const getTextColor = (sectionIndex: number) => {
    return selectedSection === sectionIndex ? "" : "#8874cd";
  };

  return (
    <section className=" flex flex-col items-center gap-6 justify-center text-white">
      <div className=" flex flex-row items-center gap-6 max-lg:px-4 max-sm:max-w-[380px] max-sm:overflow-x-scroll">
        <div
          onClick={() => handleSectionClick(1)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(1)}] text-[${getTextColor(
                1
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <GoDependabot className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            LLM Models
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(2)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(2)}] text-[${getTextColor(
                2
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <PiChatsDuotone className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            AI Chat
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(3)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(3)}] text-[${getTextColor(
                3
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <MdSupportAgent className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            Data Agents
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(4)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(4)}] text-[${getTextColor(
                4
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <VscDatabase className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            AI Pipeline
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(5)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(5)}] text-[${getTextColor(
                5
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <CiSearch className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            AI Search Engine
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(6)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(6)}] text-[${getTextColor(
                6
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <TbPlugConnected className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            AI Cloud Storage
          </p>
        </div>
        <div
          onClick={() => handleSectionClick(7)}
          className={`flex items-center flex-col gap-1 cursor-pointer `}
        >
          <div className="flex">
            <div
              className={`bg-[${getBackgroundColor(7)}] text-[${getTextColor(
                7
              )}] p-2 rounded-md cursor-pointer hover:scale-110 duration-300`}
            >
              <MdEngineering className=" text-[35px] max-lg:text-[25px]" />
            </div>
          </div>
          <p className="text-[#8874cd] max-lg:text-center max-lg:text-[15px]">
            AI Forms
          </p>
        </div>
      </div>
      <AnimatePresence>
        <div className=" py-4">
          {selectedSection === 1 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Pick Your Power - Unlimited LLM Models Tailored for You.
                </p>
              </div>
              <img
                src={Models}
                alt="LLmModels"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 2 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className=" flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Chat Smart - The Future of Advanced AI Conversations.
                </p>
              </div>
              <img
                src={Chat}
                alt="Chat"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 3 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Big Data, Smart AI - Data Agents at Your Service.
                </p>
              </div>
              <img
                src={Agents}
                loading="lazy"
                alt="Data Agents"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 4 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Streamline with AI - Custom Pipelines for Advanced Tasks.
                </p>
              </div>
              <img
                src={Pipeline}
                loading="lazy"
                alt="AI Pipelines"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 5 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Search Reimagined - Experience boxMind's Advanced AI Search.
                </p>
              </div>
              <img
                src={Search_Engine}
                loading="lazy"
                alt="Search Engine"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 6 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  Your Cloud, Our AI - Seamlessly Integrate with boxMind.
                </p>
              </div>
              <img
                src={Cloud}
                loading="lazy"
                alt="Cloud Storage"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
          {selectedSection === 7 && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              className="flex flex-col px-4"
            >
              <div className=" flex justify-center py-2 pb-8 text-gray-800 text-[25px] lg:text-[32px] font-semibold">
                <p className=" text-black max-lg:text-center">
                  The new innovative AI Form
                </p>
              </div>
              <img
                src={AIForm}
                loading="lazy"
                alt="AIForm"
                className=" border border-sky-800 shadow-xl w-full lg:w-[1000px] h-[181px] md:h-[350px] lg:h-[475px]"
              />
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </section>
  );
};

export default DashboardSection;
