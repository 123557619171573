const NotFoundPage = () => {
  return (
    <section className="bg-[#F8FBFD] overflow-hidden text-black lg:pt-[5%] pt-[25%]">
      <div className=" flex flex-col justify-center items-center gap-4 h-[100dvh]">
        <div className=" text-[30px]">Page not found!</div>
        <a
          href="/"
          className=" px-4 py-2 bg-[#135991] text-white hover:bg-[#1b3244] duration-300 rounded-md"
        >
          Go back!
        </a>
      </div>
    </section>
  );
};

export default NotFoundPage;
