import { Helmet } from "react-helmet";
import Hero from "./Hero/Hero";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardSection from "./DashboardSection";
// import Product from "./Product";
import SubSection1 from "./SubSection1";
import SubSection2 from "./SubSection2";
import SubSection3 from "./SubSection3";
// import Trial from "./Trial";
import ContactUs from "./ContactUs";
import SubSection4 from "./SubSection4";
import SubSection5 from "./SubSection5";
import SubSection6 from "./SubSection6";
import SubSection7 from "./SubSection7";
import SubSection8 from "./SubSection8";
import SubSection9 from "./SubSection9";
import SubSection10 from "./SubSection10";
import SubSection11 from "./SubSection11";
import VideoSection from "./VideoSection";
import SubSection12 from "./SubSection12";

const HomePage = () => {
  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  return (
    <section className=" bg-[#F8FBFD] overflow-hidden flex flex-col gap-6">
      <Helmet>
        <title>boxMind</title>
        <meta
          name="description"
          content="Discover boxMind, the future of enterprise AI. Explore our Large Language Models (LLMs), AI Chat, Data Agents, AI Search Engine, and more.
Experience a new era of data analysis with privacy and efficiency at its core."
        />
      </Helmet>
      {/* <Slider {...sliderSettings}> */}
      <VideoSection />
      <Hero />
      {/* <div>
          <div className=" lg:pt-[5%] pt-[25%] banner lg:h-[85vh] bg-no-repeat overflow-hidden bg-cover w-full pb-8 lg:pb-0">
            <h2>Component 2</h2>
          </div>
        </div> */}
      {/* </Slider> */}
      <DashboardSection />
      {/* <Product /> */}
      <SubSection1 />
      <SubSection2 />
      <SubSection3 />
      <SubSection4 />
      <SubSection5 />
      <SubSection6 />
      <SubSection11 />
      <SubSection12 />
      <SubSection8 />
      <SubSection7 />
      <SubSection10 />
      <SubSection9 />
      <div id="contact-us">
        <ContactUs />
      </div>
      {/* <Trial />
      <ContactUs /> */}
    </section>
  );
};

export default HomePage;
