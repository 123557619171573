import Image from "../../../Assets/Man_Saving_Money_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection4 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row items-center lg:gap-[140px] gap-[50px]">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img src={Image} alt="pic" className=" h-[389px]" loading="lazy" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[50px] leading-tight text-[32px] font-semibold max-lg:text-center ">
              High-End AI, Economically Delivered
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              In the world of enterprise AI, cost-effectiveness is paramount.
              boxMind strikes a perfect balance between quality and
              affordability. Our approach involves rigorous testing of
              open-source models to ensure we deliver an uncompromising quality
              at a fraction of the usual cost. This economic approach to AI
              solutions means businesses can enjoy high-end AI capabilities
              without the high-end price tag, ensuring a greater return on
              investment and a wider accessibility to cutting-edge technology.
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection4;
