import React, { useEffect, useState } from "react";
import BoxMind from "../../Assets/boxMind_wide.png";
import { Link, useLocation } from "react-router-dom";
import { GrYoutube } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";

const NavBar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav
      className={`w-full fixed z-50 transition-all ${
        pathname === "/"
          ? scrolling
            ? "bg-[#F8FBFD]"
            : "bg-transparent"
          : "bg-[#F8FBFD]"
      }`}
    >
      <div className=" mx-auto flex flex-row items-center justify-between lg:px-[100px] px-[30px] py-[16px]">
        <Link to={"/"}>
          <img
            src={BoxMind}
            loading="lazy"
            alt="boxMind"
            width={150}
            height={58}
          />
        </Link>
        <div className={`lg:flex hidden flex-row gap-[35px] text-gray-400`}>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/" ? "text-[#135991] duration-300" : null
            }`}
            to="/"
          >
            Home
          </Link>
          {/* <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/ai-heroes" ? "text-[#135991] duration-300" : null
            }`}
            to="/ai-heroes"
          >
            AI Heroes
          </Link> */}
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/ai-ethics" ? "text-[#135991] duration-300" : null
            }`}
            to="/ai-ethics"
          >
            AI Ethics
          </Link>
          {/* <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/use-cases" ? "text-[#135991] duration-300" : null
            }`}
            to="/use-cases"
          >
            Use Cases
          </Link> */}
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/co-founders" ? "text-[#135991] duration-300" : null
            }`}
            to="/co-founders"
          >
            Co Founders
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/philosophy" ? "text-[#135991] duration-300" : null
            }`}
            to="/philosophy"
          >
            Philosophy
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/faq" ? "text-[#135991] duration-300" : null
            }`}
            to="/faq"
          >
            FAQ
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/events" ? "text-[#135991] duration-300" : null
            }`}
            to="/events"
          >
            Events
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/articles" ? "text-[#135991] duration-300" : null
            }`}
            to="/articles"
          >
            Articles
          </Link>
        </div>
        <div
          className={`lg:flex hidden flex-row items-center gap-4 text-gray-400`}
        >
          <a
            href="https://youtube.com/@boxMindai"
            target="blank"
            className={` hover:text-[red] duration-300`}
          >
            <GrYoutube size={25} />
          </a>
          <a
            href="https://www.linkedin.com/company/boxmindai"
            target="blank"
            className={` hover:text-[#135991] duration-300`}
          >
            <FaLinkedinIn size={20} />
          </a>
        </div>
        <div className="lg:hidden block">
          <button onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="lg:hidden block bg-gray-100 py-2">
          <Link
            onClick={toggleMenu}
            to="/"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Home
          </Link>
          {/*
          <Link
            onClick={toggleMenu}
            to="/ai-heroes"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            AI Heroes
          </Link>
          */}
          <Link
            onClick={toggleMenu}
            to="/ai-ethics"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Ai Ethics
          </Link>
          <Link
            onClick={toggleMenu}
            to="/use-cases"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Use Cases
          </Link>
          <Link
            onClick={toggleMenu}
            to="/co-founders"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Co Founders
          </Link>
          <Link
            onClick={toggleMenu}
            to="/Philosophy"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Philosophy
          </Link>
          <Link
            onClick={toggleMenu}
            to="/faq"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            FAQ
          </Link>
          <Link
            onClick={toggleMenu}
            to="/events"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Events
          </Link>
          <Link
            onClick={toggleMenu}
            to="/articles"
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Articles
          </Link>
          <div className=" py-2 flex flex-row justify-center items-center gap-4">
            <a
              href="https://youtube.com/@boxMindai"
              target="blank"
              className={` hover:text-[red] duration-300`}
            >
              <GrYoutube size={25} />
            </a>
            <a
              href="https://www.linkedin.com/company/boxmindai"
              target="blank"
              className={` hover:text-[#135991] duration-300 `}
            >
              <FaLinkedinIn size={20} />
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
